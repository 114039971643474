@import "../variable";
@import "../mixin";

.tabs {
  width: 100%;
  padding-bottom: 80px;
  padding-top: 46px;
  .react-tabs {
    width: 100%;
  }
  .tabs-list {
    background-color: #222433;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-content: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding-bottom: 25px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      width: 80px;
      text-align: center;
      cursor: pointer;
      font-size: 10px;
      color: #ffffff;
      i {
        display: inline-block;
        margin-bottom: 5px;
      }
      span {
        width: 100%;
        @extend %fonts-medium;
      }
      &.react-tabs__tab--selected {
        rect {
          stroke: $primary-color;
        }
        line {
          stroke: $primary-color;
        }
        path {
          fill: $primary-color;
        }
      }
    }
  }
  .tabs-content {
    padding: 8px;
    .video-listing {
      li {
        cursor: pointer;
        @include prefix(box-shadow, 0 0 6px rgba(0, 0, 0, 0.16));
        @include prefix(border-radius, 7px);
        padding: 8px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        figure {
          width: 126px;
          height: 97px;
          display: inline-block;
          @include prefix(border-radius, 7px);
          overflow: hidden;
          position: relative;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          .play-btn {
            height: 49px;
            width: 49px;
            display: inline-block;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin: 0 auto;
            @include prefix(transform, translateY(-50%));
          }
        }
        strong {
          width: calc(100% - 142px);
          font-size: 18px;
          @extend %fonts-semibold;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.resources-details-bottom {
  width: 100%;
  .res-detail-video {
    max-width: 100%;
    width: 100%;
    background-color: #000;
    position: relative;
    > div {
      width: 100% !important;
      height: 215px !important;
    }
    .play-btn {
      height: 49px;
      width: 49px;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin: 0 auto;
      cursor: pointer;
      @include prefix(transform, translateY(-50%));
    }
    .play-hidden {
      visibility: hidden;
      opacity: 0;
    }
    .play-visible {
      visibility: visible;
      opacity: 1;
    }
    figure {
      width: 100%;
      height: 215px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .res-detail-info {
    padding: 20px 15px;
  }
}
.resources-details-top {
  .res-detail-info {
    padding: 20px 15px;
  }
}
.res-detail-info {
  padding: 20px 7px;

  h2 {
    font-size: 21px;
    @extend %fonts-bold;
    margin-bottom: 28px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    @extend %fonts-light;
  }
  strong {
    font-weight: bold;
  }
  ol {
    list-style: decimal;
    margin-left: 15px;
  }
  ul {
    list-style: disc;
    margin-left: 18px;
  }
}
