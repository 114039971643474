.app-header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px 15px;
    position: relative;
    border-bottom: 1px solid $color1;
    position: fixed;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 1;
    .back-icon {
        display: inline-block;
        cursor: pointer;
        position: absolute;
        left: 15px;
    }
    span {
        display: inline-block;
        width: 220px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $black;
        font-size: 17px;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        @extend %fonts-semibold;
    }
    .warn-icon {
        display: inline-block;
        cursor: pointer;
    }
}
