// Fonts Size
h1 {
    font-size: 45px;
}
h2 {
    font-size: 26px;
}
h3 {
    font-size: 22px;
}
h4 {
    font-size: 18px;
}
h5 {
    font-size: 16px;
}
p {
    font-size: 16px;
}
// Text Align
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
// Text Colors
.text-success {
    color: $success;
}
.text-danger {
    color: $danger;
}
.text-warning {
    color: $warning;
}
.text-info {
    color: $info;
}

// Width css
.w-100 {
	width: 100%;
}
// Height SCSS
.min-h100 {
	min-height: 100vh;
}
.h-100 {
    height: 100%;
}
html,body,#root {
    height: 100%;
}
// Responsive
@include responsive(md) {
   
}
