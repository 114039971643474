@import "../variable";
@import "../mixin";

.calc-main {
  height: 100%;
  position: relative;
  overflow: auto;
  text-align: center;
  background-color: #222433;
  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-left: -4px;
  }
}

.calc-wrap {
  background-color: #222433;
  max-width: 375px;
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
}

.calc-top {
  height: 272px;
  background-color: #222433;
  padding: 45px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .input-num {
    font-size: 63px;
    color: $white;
    word-break: break-all;
    @extend %fonts-popins;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
  }
}

.calc-top-sym {
  display: flex;
  background-color: #32364a;
  justify-content: space-between;
  li {
    width: 68px;
    button {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 63px;
      cursor: pointer;
      -webkit-touch-callout: none; 
	    -webkit-user-select: none; 
	    -khtml-user-select: none; 
	    -moz-user-select: none; 
	    -ms-user-select: none; 
	    user-select: none; 
      background-position: center;
      background-repeat: no-repeat;
      svg {
        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
      }
      &:disabled {
        background-color: #222433;
      }
    }
  }
}

.calc-top-disable {
  background-color: #222433;
}
.mix-btns {
  display: flex;
  width: 100%;
}
.calc-btns {
  display: flex;
  width: 82%;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-between;
  li {
    width: 78px;
    button {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 78px;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      -webkit-touch-callout: none; 
      -webkit-user-select: none; 
      -khtml-user-select: none; 
      -moz-user-select: none; 
      -ms-user-select: none; 
      user-select: none; 
      svg {
        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
      }
      &:hover {
        @include prefix(box-shadow, 0 3px 6px rgba(0, 0, 0, 0.48));
      }
      &:disabled {
        box-shadow: none;
      }
    }
  }
}
.calc-right-sym {
  display: flex;
  width: 18%;
  flex-wrap: wrap;
  background-color: #32364a;
  li {
    width: 68px;
    button {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 78px;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      -webkit-touch-callout: none; 
      -webkit-user-select: none; 
      -khtml-user-select: none; 
      -moz-user-select: none; 
      -ms-user-select: none; 
      user-select: none; 
      svg {
        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
      }
      &.eq-btn {
        height: 182px;
        background-color: $primary-color;
      }
      &:disabled {
        background-color: #222433;
      }
    }
  }
}


/* Loader Styles */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 39%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Loader Styles END */
