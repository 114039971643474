// Color Variable
$primary-color: #50aff8;
$white: #ffffff;
$black: #000000;

// Gray
$color1:#d9d9d9;

$success: #089B55;
$danger: #E5664B;
$warning: #E5B64B;
$info: #448FB8;

// media quary
$lg: 1199px;
$md: 1024px;
$sm: 767px;
$xs: 575px;

// fonts
%fonts-light {
    font-family: 'SFProTextLight';
}
%fonts-semibold {
    font-family: 'SFProTextSemiBold';
}
%fonts-bold {
    font-family: 'SFProTextBold';
}
%fonts-medium {
    font-family: 'SFProTextMedium';
}
%fonts-popins {
    font-family: 'Poppins', sans-serif;
}